.App {
  text-align: center;
}

* {
  margin: 0;
  padding: 0;
}

:root {
  
  /* Main Settings  */
  --main-background-color: rgb(0, 0, 0); /*rgb(235, 235, 235);*/
  --main-text-color:  rgb(235, 235, 235);
  --text-primary-color: rgb(255, 255, 255); /*rgb(0, 0, 0);*/
  --text-secondary-color: rgb(235, 235, 235); /*rgb(68, 68, 68);*/

  /* Nav Settings  */
  --nav-link-color: rgb(235, 235, 235); /*rgb(0, 0, 0);*/
  --nav-link-hover-color: rgb(87, 87, 87);
  --nav-link-hover-background-color: none;
  --nav-background-color: none;
  --nav-height: 150px;
  --nav-logo-width: 75px;
  /*--nav-logo-font-size: 30px;*/

  /* Mobile Nav Settings  */
  --mobile-nav-background-color: rgb(65, 65, 65); /*rgb(155, 155, 155);*/
  --mobile-nav-logo-height:150px;
  --mobile-nav-link-color: rgb(65, 65, 65);
  --mobile-nav-link-hover-color: rgb(29, 29, 29);
  --mobile-nav-link-size: 20px;

  /* Card Settings  */
  --card-background-color: rgb(30, 30, 30); /*rgb(216, 216, 216)*/
  --card-border-radius: 10px;
  --card-shadow: 10px 10px 20px -13px rgb(121, 121, 121);

  /* Minting Area */
  --minting-box-color: rgb(20 , 28, 35); /*#151A1F;*/
  --minting-box-heading-color: white;
  --minting-box-info-box-color: rgb(65, 65, 65);
  --minting-box-text-color: white;


  /* Minting Button */
  --minting-button-color: linear-gradient(175deg, rgb(230, 57, 24) 0%, rgb(135, 16, 2) 100%); /*linear-gradient(180deg,#604ae5 0%,#813eee 100%)*/
  --minting-button-text-color: white;

}

body {
  color: var(--main-text-color);
  position: relative;
  background: var(--main-background-color);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

nav {
  background: var(--nav-background-color);
  width: 100%;
  height: var(--nav-height);
  transition: height 0.2s ease-in-out;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 700px; /*900px*/
  margin: auto;
}

.nav-logo {
  width: var(--nav-logo-width);
  opacity: 0.925; /* 255 -> 235 */
}

nav a {
  font-size: var(--nav-logo-font-size);
  color: var(--nav-link-color);
  text-decoration: none;

  padding: 20px;
}

nav a:hover {
  color: var(--nav-link-hover-color);
  background-color: var(--nav-link-hover-background-color);
}

a {
  color: var(--main-text-color);
}

.mobile-menu-button {
  height: 30px;
  width: 30px;
  position: fixed;
  top: 37px;
  right: -50px;
  transition: right 0.2s ease-in-out;
  z-index: 45;
  cursor: pointer;
}

.open-menu {
  left: 0px !important;
}

.mobile-nav {
  position: fixed;
  min-height: 100vh;
  background: var(--mobile-nav-background-color, rgb(117, 69, 69));
  left: -100%;
  z-index: 50;
  width: 100%;
  transition: left 0.3s ease;
}

.mobile-nav-close-button {
  height: 30px;
  width: 30px;
  position: absolute;
  top: 37px;
  right: 37px;
  cursor: pointer;
}

.mobile-nav-close-button img {
  height: 100%;
  width: 100%;
}

.mobile-nav ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 40px;
  list-style: none;
}

.mobile-nav li {
  list-style: none;
  padding: 20px;
}

.mobile-nav a {
  text-decoration: none;
  color: var(--mobile-nav-link-color);
  font-size: var(--mobile-nav-link-size);
}

.mobile-nav a:hover {
  color: var(--mobile-nav-link-hover-color);
}

.mobile-nav-logo {
  height: var(--nav-logo-height);
}

.text-muted {
  color: darkgrey;
}

.nav-social {
  width: 30px;

  padding: 0 0 0 10px;
}

.social-icons {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 800px) {
  .hide-800 {
    display: none;
  }

  .mobile-menu-button {
    right: 37px;
  }

  .nav-logo {
    height: 75px !important;
  }

  nav {
    height: 100px;
  }
}

.text-start {
  text-align: start;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.pb-2 {
  padding-bottom: 20px;
}

.pb-3 {
  padding-bottom: 30px;
}

.pb-4 {
  padding-bottom: 40px;
}

header {
  max-width: 700px; /*900px*/
  margin: auto;
  align-items: center;
  border-radius: 30px;
  margin-bottom: 40px;
  /*display: grid;*/
  gap: 40px;
  /*padding: 35px;*/
  flex-direction: column;

  justify-content: center;
  padding: 35px 0;
}

.mintHeader {
  flex-direction: column;
  gap: 20px;
}

@media only screen and (min-width: 800px) {
  header {
    /*grid-template-columns: 1fr 1fr;*/
    padding: 40px;
    gap: 20px;
  }
}

.text-primary-color {
  color: var(--text-primary-color);
}

.text-secondary-color {
  color: var(--text-secondary-color);
}

.team {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
  padding: 20px;
  margin: 40px auto;
  max-width: 700px; /*900px*/
}

.team-item {
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 0 20px -13px white;
}

.faq {
  max-width: 700px; /*900px*/
  margin: auto;
  align-items: center;
  padding: 20px;
}

.faq div {
  margin-bottom: 20px;
}

.faq p {
  padding: 20px;
}

hr {
    border: none;
    height: 1px;
    /* Set the hr color */
    color: rgb(100, 100, 100); /* old IE */
    background-color: rgb(100, 100, 100); /* Modern Browsers */
}

.rainbow-gallery {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.rainbow-gallery img {
  width: 100%;
}

.card {
  align-items: center;
  border-radius: var(--card-border-radius);
  padding: 40px;
  background: var(--card-background-color);
  box-shadow: var(--card-shadow);
}

.container {
  position: relative;
  margin-top: 40px;
  margin-bottom: 40px;
  /*margin-left: auto;*/
  /*margin-right: auto;*/
  max-width: 700px; /*900px*/
}

.content-wrapper {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 700px; /*900px*/
  margin: auto;
}

.mints-available {
  background: rgb(65, 65, 65);
  box-shadow: none;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
}

.minting-box {
  background-color: var(--minting-box-color) !important;
  color: var(--minting-box-text-color) !important;
}

.minting-box h5 {
  color: var(--minting-box-heading-color);
}

.minting-info {
  background-color: var(--minting-box-info-box-color);
  box-shadow: none;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
}

.minting-info h1 {
  font-size: 3rem;
  margin-bottom: 10px;
}

.minting-button {
  background: var(--minting-button-color) !important;
}

.custom-mint-container {
  margin-bottom: 20px;
 }



.loading:after {
  content: " .";
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}


.MuiDialogTitle-root {
    background-color: rgb(33,33,33) !important;
}

.MuiCircularProgress-colorPrimary {
    color: white !important;
}

.MuiCircularProgress-root { 
    /*margin-top: 9px;*/
    height: 25px !important;
    width: 25px !important;
}

.MuiSnackbar-root {
    bottom: unset !important;
    top: 25px !important;
}

.MuiAlert-standardError {
    color: rgb(235, 235, 235) !important; /*rgb(250, 179, 174)*/
    background-color: rgb(135, 16, 2) !important;
}
.MuiAlert-standardSuccess {
    color: rgb(235, 235, 235) !important; /*rgb(183, 223, 185)*/
    background-color: #0c5900 !important; /*rgb(7, 17, 7)*/
}

.custom-mint-container {
  text-align: center;
}

/* WL */
.mintHeader {
  min-width: 300px;
}
.minting-info {
  margin-bottom: 30px;
}

.header-video {
  margin: 0 auto;
  width: 300px;
}
.hidden {
  display: none;
}